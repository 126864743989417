import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import ExternalArticle from "./externalArticle"

function RefStefano() {

  const pageQuery = useStaticQuery(graphql`
    query {
      img1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "img1.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      img2: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "img2.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      img3: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "img3.jpeg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      img4: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "img4.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      img5: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "img5.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      img6: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "img6.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      img7: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "img7.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      img8: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "img8.webp"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      img9: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "img9.webp"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      video1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "video1.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      video2: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "video2.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      video3: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "video3.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      video4: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "video.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
      video5: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "video5.webp"}) {
        childImageSharp {
          gatsbyImageData(
            width: 300,
            placeholder: BLURRED,
            formats: [WEBP],
            quality: 100
          )
        }
      }
    }
  `)
  const images = [
    {
      text: 'Bora Varejo faz evento histórico em São Paulo',
      image: getImage(pageQuery.img9),
      alt: "Stéfano Willig CEO da Awise QuantoSobra palestrando no evento Bora Varejo",
      url: "https://exame.com/colunistas/bora-varejo/bora-varejo-faz-evento-historico-em-sao-paulo/"
    },
    {
      text: 'Empresários apresentam tendências e o futuro do varejo pós NRF',
      image: getImage(pageQuery.img8),
      alt: "CEO da Awise QuantoSobra, Stéfano Willig apresentando tendências de gestão no Bora Varejo",
      url: "https://www.moneyreport.com.br/negocios/empresarios-apresentam-tendencias-e-o-futuro-do-varejo-pos-nrf/"
    },
    {
      text: "QuantoSobra apresenta seu case de marketing digital",
      image: getImage(pageQuery.img1),
      alt: "QuantoSobra apresenta seu case de marketing digital",
      url: "https://www.ativales.com.br/2018/03/27/quantosobra-apresenta-seu-case-de-marketing-digital/",
    },
    {
      text: "4ª edição do Café & Negócios",
      image: getImage(pageQuery.img2),
      alt: "4ª edição do Café & Negócios",
      url: "http://www.cdl.org.br/noticias/185/4%C2%AA-edi%C3%A7%C3%A3o-do-Caf%C3%A9-&-Neg%C3%B3cios",
    },
    {
      text: "O que os maiores CEOS falam do user onboarding",
      image: getImage(pageQuery.img3),
      alt: "O que os maiores CEOS falam do user onboarding",
      url: "https://startupi.com.br/2018/08/o-que-os-maiores-ceos-falam-do-user-onboarding/",
    },
    {
      text: "Estudo de caso: triplicando a base de clientes usando Inside Sales",
      image: getImage(pageQuery.img4),
      alt: "Estudo de caso: triplicando a base de clientes usando Inside Sales",
      url: "https://meetime.com.br/blog/podcast/estudo-de-caso-sobre-vendas/",
    },
    {
      text: "Marketing Digital para empresas será tema de palestra na UNISC",
      image: getImage(pageQuery.img5),
      alt: "Marketing Digital para empresas será tema de palestra na UNISC",
      url: "https://www.gaz.com.br/marketing-digital-para-empresas-sera-tema-de-palestra-na-unisc/",
    },
    {
      text: 'Encerramos uma “divisão” da empresa que gerava 30% das nossas receitas mensais',
      image: getImage(pageQuery.img6),
      alt: "Encerramos uma “divisão” da empresa que gerava 30% das nossas receitas mensais",
      url: "https://administradores.com.br/artigos/porque-encerramos-uma-divisao-da-empresa-que-chegou-a-gerar-30-das-nossas-receitas-mensais",
    },
    {
      text: 'How Being Prepared Made this Startup Grow during the COVID19 Pandemic',
      image: getImage(pageQuery.img7),
      alt: "How Being Prepared Made this Startup Grow during the COVID19 Pandemic",
      url: "https://startup.info/stefano-nonnenmacher-willig-quantosobra/",
    },
  ]
  const videos = [
    {
      image: getImage(pageQuery.video5),
      alt: "Assista Stéfano Willig trazer as principais tendências de gestão no varejo",
      url: "https://www.youtube.com/live/8n-RJ0m9wOM?feature=share&t=11641"
    },
    {
      image: getImage(pageQuery.video3),
      alt: "Vídeo Stéfano",
      url: "https://youtu.be/AX4sEep1G3w"
    },
    {
      image: getImage(pageQuery.video4),
      alt: "Vídeo Stéfano",
      url: "https://youtu.be/W5JSr45-9dc"
    },
    {
      image: getImage(pageQuery.video2),
      alt: "Vídeo Stéfano",
      url: "https://youtu.be/Be3KWYZpOFg"
    },
    {
      image: getImage(pageQuery.video1),
      alt: "Vídeo Stéfano",
      url: "https://youtu.be/eVBfaz0RfWo"
    },
  ]

  return (
    <div className="bg-chrome w-full h-full">
      <div className="flex flex-col gap-8 pt-10 pb-20 mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">

      <h2 className="font-bold text-3xl w-60 md:w-auto" >Encontre <strong className="text-middleGreen font-bold">Stéfano Willig</strong> em outros canais</h2>

      <div className="flex flex-row justify-between items-center">
        <ul className="w-full xl:w-7/12 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 justify-items-center lg:justify-items-start gap-x-3 gap-y-5 text-xl font-semibold">

          {images.map((content, i) => <ExternalArticle key={i} image={content.image} alt={content.alt} text={content.text} url={content.url} /> )}

        </ul>

        <ul className="hidden lg:flex flex-col items-end gap-y-2">

          {videos.map((content, i) => <ExternalArticle key={i} image={content.image} alt={content.alt} url={content.url} isVideo /> )}

        </ul>
      </div>

      </div>
    </div>
  )
}

export default RefStefano