import React from "react"

import "../../../css/professionalBox.css"

function BioStefano() {

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-6 mb-10 mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl text-display">

      <div className="flex flex-col gap-6 lg:w-6/12 xl:w-8/12 text-xl">
        <h2 className="font-bold text-3xl w-60 md:w-auto" >Quem é <strong className="text-middleGreen font-bold">Stéfano Willig</strong>?</h2>

        <h3 className="font-bold text-2xl">Biografia</h3>

        <p>Com passagem por diversas empresas dos mais diferentes segmentos, desde imobiliárias, até indústrias e varejos, Stéfano 
        teve oportunidade de atuar na gestão financeira, comercial/vendas, estoque, e setor de compras de várias empresas.</p>

        <p>Com esta experiência prática, após algumas tentativas, acabou abrindo o QuantoSobra, que prosperou devido à sua paixão 
        por entender o que leva uma empresa ao sucesso.</p>

        <p>Stéfano atende diversos clientes e aprende na prática sobre o dia-a-dia destes negócios, ajudando a encontrar 
        soluções para os problemas que eles enfrentam.</p>

        <p>Em seu negócio, estuda constantemente e busca as melhores práticas nacionais e internacionais para aplicar na 
        sua empresa, além de usar este conhecimento adquirido para escrever sobre estes temas e ajudar seus clientes.</p>
      </div>

      <div className="professionalBoxSize relative flex flex-col gap-5 w-auto h-auto bioShadow rounded-lg p-5">

        <h3 className="font-bold text-2xl">Profissional</h3>

        <ol className="flex flex-col gap-y-4 md:gap-y-10 pl-10 text-xl">
          <li>Bacharel em Direito</li>
          <li className="flex flex-col gap-y-4 font-bold">Experiência em:
            <ul className="flex flex-col gap-y-4 font-normal m-0">
              <li className="flex">Empreendedorismo e Gestão Ágil</li>
              <li className="flex">Vendas e Gestão de Métricas</li>
              <li className="flex">Relacionamento com Mercado</li>
              <li className="flex">Marketing Digital</li>
              <li className="flex">Gestão de Pessoas</li>
            </ul>
          </li>
        </ol>

        <div className="professionMarkerPosition bg-chrome rounded-full">
          <div className="firstMarker" />
          <div className="secondMarker" />
          <div className="thirdMarker" />
          <div className="fourthMarker" />
          <div className="fifthMarker" />
          <div className="sixthMarker" />
          <div className="seventhMarker" />
        </div>

      </div>

    </div>
  )
}

export default BioStefano