import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

function ExternalArticle( { image, alt, text, url, isVideo } ) {

  return (
    <li className={`flex flex-col gap-y-4 ${text ? "w-36 md:w-40 lg:w-52 " : "w-80"}`}>
      <a href={url} target="_blank" rel="noreferrer" className="flex flex-col gap-y-2">
        <GatsbyImage image={image} alt={alt} className={`object-cover ${text ? "h-24 xl:h-28" : "h-auto"} ${isVideo ? "shadow-xl hover:shadow transition" : "rounded-t-md"}`} />
        {text ? text : null}
      </a>
    </li>
  )
}

export default ExternalArticle